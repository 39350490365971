// Dates (1/2)
const today = new Date();
const tomorrow = new Date();
tomorrow.setDate( today.getDate() + 1 );
const lastSunday = new Date();
lastSunday.setDate( today.getDate() - today.getDay());

// Private helpers
const getDayRelativeToLastSunday = ( dayNumber ) => {
  const d = new Date( lastSunday.getTime());
  d.setDate( d.getDate() + dayNumber );
  return d;
};

const getDaysRelativeToLastSunday = ( initialDayNumber, endDayNumber ) => {
  const result = [];

  for ( let i = initialDayNumber; i <= endDayNumber; i += 1 ) {
    result.push( getDayRelativeToLastSunday( i ));
  }

  return result;
};

// Dates (2/2)
const thisWeek = getDaysRelativeToLastSunday( 1, 7 ); // Last Monday to next Sunday

const thisWeekend = getDaysRelativeToLastSunday( 6, 7 ); // This Saturday & Sunday

const nextWeek = getDaysRelativeToLastSunday( 8, 14 ); // Next Monday up to following Sunday

// Public methods
const isToday = ( day ) => {
  switch ( day ) {
    case 'sunday':
      return today.getDay() === 0;
    case 'monday':
      return today.getDay() === 1;
    case 'tuesday':
      return today.getDay() === 2;
    case 'wednesday':
      return today.getDay() === 3;
    case 'thursday':
      return today.getDay() === 4;
    case 'friday':
      return today.getDay() === 5;
    case 'saturday':
      return today.getDay() === 6;
    default:
      return false;
  }
};

const dateToYMD = ( date ) => {
  let d = date.getDate();
  let m = date.getMonth() + 1; // Month from 0 to 11
  const y = date.getFullYear();

  // Add leading zeroes
  m = `0${m}`.slice( -2 );
  d = `0${d}`.slice( -2 );

  return `${y}-${m}-${d}`;
};

// Service export
export default {
  today,
  tomorrow,
  thisWeek,
  thisWeekend,
  nextWeek,
  dateToYMD,
  isToday,
};
