import React, { useContext } from 'react';
import cls from 'classnames';

import { ISearchAggregationBucket } from 'src/explore/types/shoppe';

import { CheckboxItem } from './elements/CheckboxItem';
import { FilterItem } from '../FilterItem';
import FiltersContext from '../../../../hooks/FiltersContext';

const labelMaker = ( key: string ) => {
  switch ( key ) {
    case 'combo':
      return 'Combos';
    case 'food':
      return 'Foods';
    case 'gift_card':
      return 'Gift Cards';
    case 'live_class':
      return 'Live Classes';
    case 'mealkit':
      return 'Meal Kits';
    case 'merch':
      return 'Merchandise';
    case 'subscription':
      return 'Subscriptions';
    default:
      return key;
  }
};

interface ProductTypeFilterProps {
  className?: string;
  filters?: ISearchAggregationBucket[];
}

export const ProductTypeFilter = ({ className, filters = []}: ProductTypeFilterProps ) => {
  const { appliedFilters, onFilterChange } = useContext( FiltersContext );

  const handleChange = ( filter: string, isChecked: boolean ) => {
    let activeFilters = appliedFilters['product_types[]'] || [];
    if ( typeof activeFilters === 'string' ) {
      activeFilters = activeFilters.split( ',' );
    }

    onFilterChange({
      'product_types[]': isChecked
        ? activeFilters.filter(( activeFilter: string ) => !filter.includes( activeFilter ))
        : [ activeFilters, filter ].flat(),
    });
  };

  if ( filters.length < 1 ) return null;

  return (
    <FilterItem className={cls( 'spec__filter-product-types', className )} name="Product Types">
      <ul>
        {filters.map(( filter ) => (
          <li className="mt-6 mt-lg-4" key={filter.key}>
            <CheckboxItem
              checked={!!appliedFilters['product_types[]']?.includes( filter.key as string )}
              label={labelMaker( filter.key as string )}
              value={filter.key as string}
              onChange={handleChange}
            />
          </li>
        ))}
      </ul>
    </FilterItem>
  );
};
