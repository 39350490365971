import React from 'react';

interface ApplyButtonProps {
  disabled: boolean;
  onClick: () => void;
}

export const ApplyButton = ({ disabled, onClick }: ApplyButtonProps ) => (
  <div className="d-lg-none pt-7 pb-9 text-center">
    <button
      className="btn btn-block btn-secondary py-3 mb-8 font-weight-bold fs-7"
      disabled={disabled}
      type="submit"
      onClick={onClick}
    >
      Apply
    </button>
  </div>
);
