import React, { useState } from 'react';

import RangeFilter from 'src/explore/elements/RangeFilter';

import { ntc } from 'src/explore/services/currency_helper';

import TipFormatter from '../../services/PriceTipFormatter';

export const Price = ({ defaultValue, onChange, ...props }) => {
  const [ value, setValue ] = useState( defaultValue );

  let { min, max } = props;
  // Round cents
  min = Math.floor( min / 100 ) * 100;
  max = Math.ceil( max / 100 ) * 100;

  const marks = {};
  // Create marks
  marks[min] = ntc( min, { verbose: false });
  marks[max] = ntc( max, { verbose: false });

  return (
    <RangeFilter
      ariaLabel="Price"
      allowCross={false}
      defaultValue={value}
      marks={marks}
      max={max}
      min={min}
      pushable={1000}
      step={100}
      tipFormatter={TipFormatter.format}
      value={value}
      onChange={setValue}
      onAfterChange={() => onChange( value )}
    />
  );
};
