import React, { ReactNode } from 'react';
import cls from 'classnames';

import styles from './index.module.sass';

interface ButtonProps {
  children?: ReactNode;
  className?: string;
  disabled: boolean;
  highlightRed?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
}

export const Button = ({
  children,
  className,
  disabled,
  highlightRed,
  isSelected,
  onClick,
}: ButtonProps ) => (
  <button
    className={cls(
      styles.button,
      `btn px-4 py-0 font-weight-bold fs-4`,
      {
        [styles.on]: isSelected,
        [styles.highlightRed]: highlightRed,
      },
      className
    )}
    disabled={disabled}
    type="button"
    onClick={onClick}
  >
    {children}
  </button>
);
