import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import cls from 'classnames';
import debounce from 'lodash-es/debounce';
import isEqual from 'lodash-es/isEqual';

import { ntc } from 'src/explore/services/currency_helper';

import { Price } from 'src/explore/compositions/Filters/elements/Price';

import { FilterItem } from '../FilterItem';
import FiltersContext from '../../../../hooks/FiltersContext';

const PriceFilter = ({ className, max, min }) => {
  const values = useRef();
  const { appliedFilters, onFilterChange } = useContext( FiltersContext );

  useEffect(() => {
    if ( !min || !max ) return;
    if ( values.current ) return;

    values.current = [ min, max ];
  }, [ min, max ]);

  const currentValues = [ appliedFilters.min_price, appliedFilters.max_price ];

  const filterTitle = useMemo(() => {
    const { max_price, min_price } = appliedFilters;

    if ( !max_price && !min_price ) return null;

    return `${ntc( min_price, { verbose: false })} - ${ntc( max_price, { verbose: false })}`;
  }, [ appliedFilters ]);

  const handleChange = useCallback(
    debounce(
      ( newValues ) => {
        if ( isEqual( currentValues, newValues )) return;

        onFilterChange({
          min_price: newValues[0],
          max_price: newValues[1],
        });
      },
      1500,
      { trailing: true }
    ),
    [ currentValues, onFilterChange ]
  );

  // show filters only when default values present and they are different
  const showFilter = values.current && values.current[0] !== values.current[1];

  // if no prices to filter by, and no applied values
  if ( !showFilter && !currentValues[0] && !currentValues[1]) return null;

  return (
    <FilterItem
      className={cls( 'filter--price spec__filter-price', className )}
      currentValue={filterTitle}
      loading={!values.current}
      name="Price"
      onTitleClick={() => onFilterChange({ min_price: null, max_price: null })}
    >
      <div className="px-4 pt-2 pb-4 mt-2">
        {showFilter && (
          <Price
            defaultValue={values.current}
            max={values.current[1]}
            min={values.current[0]}
            onChange={handleChange}
          />
        )}
      </div>
    </FilterItem>
  );
};

export default PriceFilter;
