import React, { useContext } from 'react';
import cls from 'classnames';

import TreeFilter from '../TreeFilter';

import FiltersContext from '../../../../hooks/FiltersContext';

const DietFilter = ({ className, tags }) => {
  const { appliedFilters, onFilterChange } = useContext( FiltersContext );

  if ( !tags?.length ) return null;

  return (
    <TreeFilter
      activeFilter={appliedFilters.tags}
      className={cls( 'spec__filter-diet', className )}
      defaultTitle="Diet"
      filters={appliedFilters.tags ? [] : tags}
      onBack={() => onFilterChange({ tags: undefined })}
      onNavigate={( filter ) => onFilterChange({ tags: filter })}
    />
  );
};

export default DietFilter;
