import React, { useEffect } from 'react';

import { FilterItem } from '../FilterItem';

import useHistory from '../../../../hooks/useHistory';

import styles from './index.module.sass';

const TreeFilter = ({ activeFilter, className, defaultTitle, filters, onBack, onNavigate }) => {
  const [ history, pop, push ] = useHistory();

  // Save first value to history if set
  useEffect(() => {
    if ( history.length === 0 && activeFilter ) push( activeFilter );
  }, [ activeFilter, history, push ]);

  const handleNavigateBack = () => {
    onBack( history[history.length - 2]);
    pop();
  };

  const handleNavigateForward = ( filter ) => {
    onNavigate( filter.key );
    push( filter.key );
  };

  return (
    <FilterItem
      backTitle={history[history.length - 2]}
      className={className}
      currentValue={activeFilter}
      name={defaultTitle}
      onTitleClick={handleNavigateBack}
    >
      {filters?.length > 0 && (
        <ul>
          {filters.map(( filter ) => (
            <li className="mt-6 mt-lg-4" key={filter.key}>
              <button
                className={`${styles.button} mw-100 p-0 border-0 bg-transparent text-dark font-weight-bold fs-5 text-left text-capitalize`}
                type="button"
                onClick={() => handleNavigateForward( filter )}
              >
                {filter.key} {`(${filter.doc_count})`}
              </button>
            </li>
          ))}
        </ul>
      )}
    </FilterItem>
  );
};

export default TreeFilter;
