import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import styles from './index.module.sass';

interface HeaderProps {
  onClickClose: () => void;
}

export const Header = ({ onClickClose }: HeaderProps ) => (
  <div className="d-lg-none mb-7 text-center">
    <div
      className={`${styles.headline} fs-8 font-weight-bold ff-futura-bold lh-1 text-dark text-uppercase`}
    >
      Filters
    </div>

    <button
      aria-label="close"
      className={`${styles.close} position-absolute border-0 bg-transparent text-dark`}
      type="button"
      onClick={onClickClose}
    >
      <FontAwesomeIcon className="fs-8" icon={faTimes} />
    </button>
  </div>
);
