import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersSimple } from '@fortawesome/pro-regular-svg-icons';

import styles from './index.module.sass';

interface OverlayTriggerProps {
  filterCount?: number;
  onClick: () => void;
}

export const OverlayTrigger = ({ filterCount, onClick }: OverlayTriggerProps ) => (
  <button
    className={`${styles.button} btn d-flex d-lg-none justify-content-center align-items-center px-4 font-weight-bold fs-4 spec__filter-button`}
    type="button"
    onClick={onClick}
  >
    <FontAwesomeIcon className="mr-2 fs-7" icon={faSlidersSimple} />
    Filter
    {!!filterCount && ` (${filterCount})`}
  </button>
);
