import * as React from 'react';

import { ISearchAggregations } from 'src/explore/types/shoppe';
import { Sorting } from 'src/explore/elements/Sorting';
import { TagFilters } from 'src/explore/elements/TagFilters';

import { FilterFactory } from './elements/FilterFactory';
import { Loader } from './elements/Loader';
import { ISearchFilter } from '../../types';

import styles from './index.module.sass';

interface FiltersAndSortingProps {
  aggs?: ISearchAggregations;
  loading: boolean;
  panelFilters: ISearchFilter[];
  sortLabelFormatter: ( option: string ) => string;
  sortOptions: Array<string | null>;
  sortSelection: string | null;
  tagFilters: ISearchFilter[];
  onSort: ( value: string ) => void;
}

export const FiltersAndSorting = ({
  aggs,
  loading,
  panelFilters,
  sortLabelFormatter,
  sortOptions,
  sortSelection,
  tagFilters,
  onSort,
}: FiltersAndSortingProps ) => (
  <div className="position-relative text-dark spec__filters">
    {!!tagFilters.length && (
      <TagFilters
        aggs={aggs}
        availableFilters={tagFilters}
        className={`${styles.tags} position-relative d-lg-none py-5`}
        loading={loading}
      />
    )}

    {!!sortOptions.length && (
      <Sorting
        className="d-lg-none py-8 border-bottom"
        format="list"
        labelFormatter={sortLabelFormatter}
        value={sortSelection}
        values={sortOptions}
        onChange={onSort}
      />
    )}

    <ul>
      {panelFilters.map(( filter: ISearchFilter ) => (
        <FilterFactory aggs={aggs} className={styles.panel} filter={filter} key={filter} />
      ))}
    </ul>

    <Loader show={loading} />
  </div>
);
