import React from 'react';
import cls from 'classnames';

import { Option } from './elements/Option';

import styles from './index.module.sass';

interface ListProps {
  className?: string;
  labelFormatter: ( option: string ) => string;
  options: Array<string | null>;
  selection: string | null;
  onSelect: ( value: string ) => void;
}

export const List = ({ className, labelFormatter, options, selection, onSelect }: ListProps ) => (
  <div className={cls( className )}>
    <h4 className={`${styles.title} text-uppercase`}>Sort</h4>

    <ul>
      {options.map(( option: string ) => (
        <li className="mt-6 spec__sorting-item" key={option}>
          <Option selected={option === selection} onClick={() => onSelect( option )}>
            {labelFormatter( option )}
          </Option>
        </li>
      ))}
    </ul>
  </div>
);
