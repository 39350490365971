import React, { ReactNode } from 'react';
import cls from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

import styles from './index.module.sass';

interface FilterItemProps {
  backTitle?: string;
  children: ReactNode;
  className?: string;
  currentValue?: string;
  loading?: boolean;
  name: string;
  onTitleClick?: () => void;
}

export const FilterItem = ({
  backTitle,
  children,
  className,
  currentValue,
  loading,
  name,
  onTitleClick,
}: FilterItemProps ) => {
  const handleClick = () => {
    if ( loading ) return;

    if ( currentValue && onTitleClick ) {
      onTitleClick();
    }
  };

  const handleKeyDown = ( event: React.KeyboardEvent<HTMLButtonElement> ) => {
    if ( event.key === 'Enter' || event.key === ' ' ) {
      handleClick();
    }
  };

  return (
    <li className={cls( 'pb-8', className )}>
      <h4 className={`${styles.title} text-uppercase spec__filter-title`}>
        {currentValue ? (
          <button
            className={`${styles.button} p-0 border-0 bg-transparent text-dark font-weight-bold fs-3 ff-futura-bold text-left text-uppercase spec__active-filter`}
            type="button"
            onClick={handleClick}
            onKeyDown={handleKeyDown}
          >
            <div className={`${styles.group} d-flex align-items-center`}>
              <FontAwesomeIcon className="mr-1" icon={faChevronLeft} />
              {backTitle || name}
            </div>
            <div className={`${styles.applied} fs-8 lh-1 mt-4`}>{currentValue}</div>
          </button>
        ) : (
          name
        )}
      </h4>

      {loading ? (
        <div className="spinner-border spinner-border-sm my-4 ml-4" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        children
      )}
    </li>
  );
};
