import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import FiltersContext, {
  IContextState,
} from 'src/explore/compositions/FilteredSearch/compositions/SearchFilter/elements/FiltersAndSorting/hooks/FiltersContext'; // FIXME: import
import { ISearchAggregations } from 'src/explore/types/shoppe';
import { IStore } from 'src/explore/types/store';

import { Filter } from '../Filter';

interface HolidayFilterProps {
  aggs: ISearchAggregations;
  loading?: boolean;
}

export const HolidayFilter = ({ aggs, loading }: HolidayFilterProps ) => {
  const [ isVisible, setIsVisible ] = useState<boolean>();

  const { appliedFilters, onFilterChange }: IContextState = React.useContext( FiltersContext );
  const { dateString, label } = useSelector(( state: IStore ) => state.data.specialDate || {});

  const isAvailable = useMemo(
    () =>
      aggs?.earliest_delivery_dates?.buckets.some(
        ( bucket ) => bucket.doc_count > 0 && ( bucket.key as number ) <= new Date( dateString ).getTime()
      ),
    [ aggs, dateString ]
  );

  if ( isVisible === undefined && !loading ) {
    setIsVisible( isAvailable );
  } else if ( isVisible === false && isAvailable ) {
    setIsVisible( true );
  }

  const active = appliedFilters.special_holiday === dateString;

  if ( !dateString || !label || !isVisible ) return null;

  return (
    <Filter
      disabled={!isAvailable}
      label={label}
      loading={loading}
      name="special_holiday"
      onClick={() => onFilterChange({ special_holiday: !active ? dateString : undefined })}
    />
  );
};
