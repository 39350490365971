import React from 'react';
import cls from 'classnames';

import styles from './index.module.sass';

export const Checkbox = ({ checked, children, className, name, type, value, onChange }) => (
  <label className={cls( styles.label, 'text-truncate', className )}>
    <input
      checked={checked}
      className={`${styles.checkbox} d-none`}
      name={name}
      type={type || 'checkbox'}
      value={value}
      onChange={onChange}
    />

    <span className={`${styles.text} position-relative pl-5`}>{children}</span>
  </label>
);
