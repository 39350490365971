import React from 'react';

import FiltersContext, {
  IContextState,
} from 'src/explore/compositions/FilteredSearch/compositions/SearchFilter/elements/FiltersAndSorting/hooks/FiltersContext'; // FIXME: import
import { ISearchFilter } from 'src/explore/compositions/FilteredSearch/compositions/SearchFilter/types'; // FIXME: import
import { ISearchAggregations } from 'src/explore/types/shoppe';

import { Filter } from '../Filter';

interface Props {
  aggs?: ISearchAggregations;
  loading?: boolean;
  name: ISearchFilter;
}

export const BadgeFilter = ({ aggs, loading, name }: Props ) => {
  const { appliedFilters, onFilterChange }: IContextState = React.useContext( FiltersContext );

  const active = appliedFilters['badges[]']?.includes( name );

  const handleOnClick = () => {
    const badges = appliedFilters['badges[]'];
    let currentBadges: string[] = [];

    if ( badges ) {
      currentBadges = typeof badges === 'string' ? badges.split( ',' ) : badges;
    }

    if ( active ) {
      onFilterChange({
        'badges[]': currentBadges.filter(( badge: string ) => !name.includes( badge )),
      });
    } else {
      const newBadges = [ currentBadges, name ].flat();

      onFilterChange({ 'badges[]': newBadges });
    }
  };

  return (
    <Filter aggs={aggs} isSelected={active} loading={loading} name={name} onClick={handleOnClick} />
  );
};
