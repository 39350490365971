import DeliveryDate from 'src/explore/services/deliveryDate';

const map = ( text ) => {
  let date;

  switch ( text ) {
    case 'tomorrow':
      date = DeliveryDate.tomorrow;
      break;
    case 'this_week':
      // "this week" is defined as Monday thru Sunday in deliveryDate, so we need to look at the second to last day
      // because the ES query is a less than or equal to comparison, we want it to look at Saturday
      date = DeliveryDate.thisWeek[DeliveryDate.thisWeek.length - 2];
      break;
    case 'next_week':
      date = DeliveryDate.nextWeek[DeliveryDate.nextWeek.length - 1];
      break;
    default:
      // Check if the value matches yyyy-mm-dd
      if ( text && text.match( /^\d{4}-\d{2}-\d{2}$/ )) {
        return text;
      }

      // This prevents the filter from filtering for wrong values
      return null;
  }

  return DeliveryDate.dateToYMD( date );
};

export default {
  map,
};
