import React, { useEffect } from 'react';
import cls from 'classnames';

import { Dropdown } from 'src/explore/elements/Dropdown';

import { LabelFormatter } from './services/LabelFormatter';
import { List } from './compositions/List';

const DEFAULT_OPTIONS = [ null, 'best_sellers', 'trending', 'price_low', 'price_high' ];

interface SortingProps {
  className?: string;
  format?: 'list';
  labelFormatter?: ( option: string ) => string;
  value: string | null;
  values: Array<string | null>;
  onChange: ( value: string ) => void;
}

export const Sorting = ({
  className,
  format,
  labelFormatter,
  value,
  values,
  onChange,
}: SortingProps ) => {
  const safeValues = values || DEFAULT_OPTIONS;

  useEffect(() => {
    // If selected value is not allowed, select first by default
    if ( !safeValues.includes( value )) {
      onChange( safeValues[0]);
    }
  }, [ onChange, value, safeValues ]);

  return format === 'list' ? (
    <List
      className={cls( className, 'spec__sorting' )}
      labelFormatter={labelFormatter || LabelFormatter.format}
      options={safeValues}
      selection={value}
      onSelect={onChange}
    />
  ) : (
    <Dropdown
      className={cls( className, 'spec__sorting v2-sorting' )}
      value={value}
      values={safeValues}
      labelFormatter={labelFormatter || LabelFormatter.format}
      onSelect={onChange}
    />
  );
};
