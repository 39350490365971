import React, { useCallback, useContext } from 'react';
import cls from 'classnames';

import TreeFilter from '../TreeFilter';

import FiltersContext from '../../../../hooks/FiltersContext';

const RegionFilter = ({ className, regions = []}) => {
  const { appliedFilters, onFilterChange } = useContext( FiltersContext );

  let activeFilter;
  let filters;
  let handleBack;
  let handleNavigate;

  const selectedState = appliedFilters['state.name'];
  const selectedRegion = appliedFilters['state.region_name'];

  const selectLocation = useCallback(
    ( region, state ) => onFilterChange({ 'state.region_name': region, 'state.name': state }),
    [ onFilterChange ]
  );

  if ( selectedRegion || selectedState ) {
    activeFilter = selectedState || selectedRegion;
    filters = selectedState ? undefined : regions[0]?.states?.buckets;
    handleBack = selectedState ? () => selectLocation( selectedRegion ) : selectLocation;
    handleNavigate = ( key ) => selectLocation( selectedRegion, key );
  } else {
    filters = regions;
    handleNavigate = selectLocation;
  }

  return (
    <TreeFilter
      activeFilter={activeFilter}
      className={cls( 'spec__filter-region', className )}
      defaultTitle="Region"
      filters={filters}
      onBack={handleBack}
      onNavigate={handleNavigate}
    />
  );
};

export default RegionFilter;
