import React, { useEffect, useState } from 'react';
import cls from 'classnames';

import { ISearchAggregations } from 'src/explore/types/shoppe';

import { ApplyButton } from './elements/ApplyButton';
import { OverlayTrigger } from './elements/OverlayTrigger';
import { FiltersAndSorting } from './elements/FiltersAndSorting';
import { Header } from './elements/Header';
import { ISearchFilter } from './types';

import styles from './index.module.sass';

export type ISource = {
  id?: string;
  text?: string;
  type: string;
};

interface SearchFilterProps {
  aggs?: ISearchAggregations;
  availablePanelFilters?: ISearchFilter[];
  availableTagFilters?: ISearchFilter[];
  count?: number;
  loading: boolean;
  sortLabelFormatter: ( option: string ) => string;
  sortOptions: Array<string | null>;
  sortSelection: string | null;
  onSort: ( value: string ) => void;
}

export const SearchFilter = ({
  aggs,
  availablePanelFilters,
  availableTagFilters,
  count,
  loading,
  sortLabelFormatter,
  sortOptions,
  sortSelection,
  onSort,
}: SearchFilterProps ) => {
  const [ open, setOpen ] = useState<boolean>( false );

  useEffect(() => {
    const handleKeyDown = ( event: KeyboardEvent ) => {
      if ( event.key === 'Escape' ) {
        setOpen( false );
      }
    };

    document.addEventListener( 'keydown', handleKeyDown );
    return () => {
      document.removeEventListener( 'keydown', handleKeyDown );
    };
  }, []);

  return (
    <>
      <OverlayTrigger filterCount={count} onClick={() => setOpen( !open )} />

      <div
        className={cls( styles.filters, 'pt-8 px-4 p-lg-0', {
          [styles.open]: open,
        })}
      >
        <Header onClickClose={() => setOpen( !open )} />

        <FiltersAndSorting
          aggs={aggs}
          loading={loading}
          panelFilters={availablePanelFilters}
          sortLabelFormatter={sortLabelFormatter}
          sortOptions={sortOptions}
          sortSelection={sortSelection}
          tagFilters={availableTagFilters}
          onSort={onSort}
        />

        <ApplyButton disabled={loading} onClick={() => setOpen( !open )} />
      </div>
    </>
  );
};
