import * as React from 'react';

import { ISearchAggregations } from 'src/explore/types/shoppe';

import ArrivalFilter from './elements/ArrivalFilter';
import CategoryFilter from './elements/CategoryFilter';
import { CuisineFilter } from './elements/CuisineFilter';
import DietFilter from './elements/DietFilter';
import { DietMerchantFilter } from './elements/DietMerchantFilter';
import PriceFilter from './elements/PriceFilter';
import { ProductTypeFilter } from './elements/ProductTypeFilter';
import RegionFilter from './elements/RegionFilter';
import { ISearchFilter } from '../../../../types';

interface Props {
  aggs?: ISearchAggregations;
  className?: string;
  filter: ISearchFilter;
}

export const FilterFactory: React.FC<Props> = ({ aggs, className, filter }) => {
  switch ( filter ) {
    case 'arrives_before':
      return (
        <ArrivalFilter
          className={className}
          earliestDate={aggs?.earliest_delivery_date?.value}
          key={filter}
        />
      );
    case 'category':
      return (
        <CategoryFilter
          categories={aggs?.nested_categories?.inner?.name?.buckets || aggs?.categories?.buckets}
          className={className}
          key={filter}
        />
      );
    case 'cuisine':
      return <CuisineFilter className={className} cuisine={aggs?.cuisine?.buckets} key={filter} />;
    case 'diet':
      return <DietFilter className={className} key={filter} tags={aggs?.tags?.buckets} />;
    case 'price':
      return (
        <PriceFilter
          className={className}
          key={filter}
          max={aggs?.max_price?.value}
          min={aggs?.min_price?.value}
        />
      );
    case 'product_tags':
      return (
        <DietMerchantFilter className={className} key={filter} tags={aggs?.product_tags?.buckets} />
      );
    case 'product_types':
      return (
        <ProductTypeFilter
          className={className}
          filters={[
            { doc_count: undefined, key: 'food' },
            { doc_count: undefined, key: 'mealkit' },
            { doc_count: undefined, key: 'combo' },
          ]}
        />
      );
    case 'region':
      return <RegionFilter className={className} key={filter} regions={aggs?.regions?.buckets} />;
    default:
      return null;
  }
};
