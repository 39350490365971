import { IAPIFilters } from '../../elements/FiltersAndSorting/hooks/FiltersContext';
import { ISearchFilter } from '../../types';

const convert = ( filters: ISearchFilter[]): Array<keyof IAPIFilters> => {
  if ( !filters ) return null;

  const result: Array<keyof IAPIFilters> = [];

  filters.forEach(( filter ) => {
    switch ( filter ) {
      case 'category':
        result.push( 'categories.name' );
        break;
      case 'diet':
        result.push( 'tags' ); // Temporary
        break;
      case 'award_winner':
      case 'gorgeous_box':
      case 'gb_top_gifts':
        result.push( 'badges[]' );
        break;
      case 'on_sale':
        result.push( 'on_sale' );
        break;
      case 'price':
        result.push( 'max_price' );
        result.push( 'min_price' );
        break;
      case 'product_types':
        result.push( 'product_types[]' );
        break;
      case 'region':
        result.push( 'state.region_name' );
        result.push( 'state.name' );
        break;
      default:
        result.push( filter );
    }
  });

  return result;
};

export const FiltersToParams = {
  convert,
};
