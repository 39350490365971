import React from 'react';
import { Checkbox } from '@goldbely/explore-component-library';

interface CheckboxProps {
  checked: boolean;
  label?: string;
  value: string;
  onChange: ( target: string, isChecked: boolean ) => void;
}

export const CheckboxItem = ({ checked, label, value, onChange }: CheckboxProps ) => {
  const handleChange = () => {
    onChange( value, checked );
  };

  return (
    <Checkbox
      className="font-weight-bold"
      checked={checked}
      label={label}
      onChange={handleChange}
    />
  );
};
