import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';

import SvgIcon from 'src/explore/elements/SvgIcon';

// Horrible hack
import styles from './index.module.sass';

const Option = ({ labelFormatter, selected, value, onSelect }) => (
  <li>
    <a href="javascript:" onClick={() => onSelect( value )}>
      {!!selected && <SvgIcon icon="icon-check" svgClasses="text-secondary" baseline />}
      {` ${labelFormatter( value )}`}
    </a>
  </li>
);

export const Dropdown = ({ className = '', value, values, labelFormatter, onSelect }) => {
  const formatter = labelFormatter || (( v ) => v );

  return (
    <div className={`dropdown ${className}`}>
      {/* the .btn-sorting is backwards compatibility :( */}
      <button
        type="button"
        className={`btn btn-sorting dropdown-toggle px-4 fs-4 spec__current-sorting ${styles.btnOutlineGray}`}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="true"
      >
        <span className="hidden-xs font-weight-normal">{'Sort By: '}</span>
        <strong className="mr-2">{formatter( value )}</strong>

        <FontAwesomeIcon icon={faChevronDown} />
      </button>

      <ul className="dropdown-menu dropdown-menu-right">
        {values.map(( v ) => (
          <Option
            key={v}
            value={v}
            selected={v === value}
            labelFormatter={labelFormatter}
            onSelect={onSelect}
          />
        ))}
      </ul>
    </div>
  );
};
