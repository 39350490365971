import React, { useContext, useEffect, useRef } from 'react';
import cls from 'classnames';

import TreeFilter from '../TreeFilter';

import FiltersContext from '../../../../hooks/FiltersContext';

const CategoryFilter = ({ className, categories = []}) => {
  const forceShow = useRef( false );
  const { appliedFilters, onFilterChange } = useContext( FiltersContext );

  useEffect(() => {
    if ( forceShow.current ) return;

    forceShow.current = categories.length > 1;
  }, [ categories ]);

  const currentFilter = appliedFilters['categories.name'];

  if ( forceShow.current || currentFilter ) {
    return (
      <TreeFilter
        activeFilter={currentFilter}
        className={cls( 'spec__filter-category', className )}
        defaultTitle="Category"
        filters={categories}
        onBack={( filter ) => onFilterChange({ 'categories.name': filter })}
        onNavigate={( filter ) => onFilterChange({ 'categories.name': filter })}
      />
    );
  }

  return null;
};

export default CategoryFilter;
