const format = ( value ) => {
  switch ( value ) {
    case null:
    case '':
      return 'Popular Now';
    case 'recommended':
      return 'Recommended';
    case 'best_sellers':
      return 'Best Sellers';
    case 'class_date_low':
      return 'Date: Earliest First';
    case 'class_date_high':
      return 'Date: Latest First';
    case 'price_low':
      return 'Price: Lowest First';
    case 'price_high':
      return 'Price: Highest First';
    default:
      return '';
  }
};

export const SortLabelFormatter = {
  format,
};
