import React from 'react';
import { Range, createSliderWithTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';

const RangeWithTooltip = createSliderWithTooltip(Range);

const RangeFilter = ({ ariaLabel = '', ...attrs }) => (
  <div className="rc-slider-wrapper">
    <RangeWithTooltip
      ariaLabelGroupForHandles={[
        `${ariaLabel} Slider Handle Min`,
        `${ariaLabel} Slider Handle Max`,
      ]}
      {...attrs}
    />
  </div>
);

export default RangeFilter;
