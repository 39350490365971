import React, { useCallback, useContext, useMemo, useState } from 'react';
import cls from 'classnames';
// TODO: replace with date-fns
// but it doesn't have full feature parity with moment (see other TODO in SingleDatePicker below)
import moment from 'moment';

import Breakpoints from 'src/explore/services/breakpoints';

import { Checkbox } from 'src/explore/elements/Checkbox';
import { SingleDatePicker } from 'src/explore/elements/SingleDatePicker';

import FiltersContext from '../../../../hooks/FiltersContext';
import { FilterItem } from '../FilterItem';
import { OptionFormatter } from './services/OptionFormatter';

import styles from './index.module.sass';

const DISCLAIMER_TEXT = 'You can specify your delivery (up to 90 days) at checkout.';

const DatepickerNote = () => (
  /* The bs4 is because the component is rendered on a portal */
  <div className="bs4">
    <div className="text-center p-4 border-bottom">
      <h3>FILTER FOODS THAT CAN ARRIVE ON OR BEFORE A SPECIFIC DATE</h3>
      <p className="text-dark">
        Have a party coming up or giving a gift? We’ll show you the products that can make it in
        time.*
        <br />
        <small className="text-muted">{`*${DISCLAIMER_TEXT}`}</small>
      </p>
    </div>
  </div>
);

const ArrivalFilter = ({ className, earliestDate }) => {
  const { appliedFilters, onFilterChange } = useContext( FiltersContext );
  const [ datePicker, setDatePicker ] = useState({
    date: null,
    focused: false,
  });
  const deadline = useMemo(() => moment( new Date()).add( 90, 'days' ), []);

  const selectedOption = appliedFilters.arrives_before || '';

  const earliestDateObj = useMemo(
    () =>
      earliestDate && moment.utc( earliestDate ).isAfter() ? moment.utc( earliestDate ) : moment(),
    [ earliestDate ]
  );

  const endOfWeekOptions = useMemo(
    () =>
      // moment defines end of week as Saturday at 11:59PM (in the user's timezone)
      earliestDate && moment.utc( earliestDate ).isBefore( moment().endOf( 'week' )),
    [ earliestDate ]
  );

  const handleFilterChange = useCallback(
    ( date ) => {
      onFilterChange({ arrives_before: date });
      setDatePicker(( state ) => ({ ...state, date: null }));
    },
    [ onFilterChange, setDatePicker ]
  );

  // If all sold out, then don't show filter
  if ( !earliestDate && !selectedOption ) return null;

  return (
    <FilterItem
      className={cls( 'spec__filter-arrival_date', className )}
      currentValue={selectedOption && OptionFormatter.format( selectedOption )}
      name="Get It By"
      onTitleClick={() => handleFilterChange( null )}
    >
      {!selectedOption && (
        <ul className="mb-n4 text-capitalize">
          <li className="mt-7 mt-lg-4">
            <Checkbox
              className={styles.option}
              type="radio"
              name="filter_arrives_before"
              checked={selectedOption === ''}
              value=""
              onChange={() => handleFilterChange( '' )}
            >
              {OptionFormatter.format( '' )}
            </Checkbox>
          </li>

          {endOfWeekOptions && (
            <li className="mt-4 mt-lg-3">
              <Checkbox
                className={styles.option}
                type="radio"
                name="filter_arrives_before"
                checked={selectedOption === 'this_week'}
                value="this_week"
                onChange={() => onFilterChange({ arrives_before: 'this_week' })}
              >
                {OptionFormatter.format( 'this_week' )}
              </Checkbox>
            </li>
          )}

          <li className="mt-2 mt-lg-1">
            <Checkbox
              type="radio"
              name="filter_arrives_before"
              checked={datePicker.focused}
              value="custom"
              onChange={() => {
                setDatePicker(( state ) => ({ ...state, focused: true }));
              }}
            >
              <div className={cls( styles.calendar, 'position-relative d-inline-block' )}>
                <SingleDatePicker
                  block
                  calendarInfoPosition="top"
                  date={datePicker.date}
                  disableScroll
                  focused={datePicker.focused}
                  hideKeyboardShortcutsPanel
                  id="delivery_date_picker"
                  isOutsideRange={( value ) =>
                    // TODO: date-fns doesn't have isSameOrBefore/isSameOrAfter
                    // https://stackoverflow.com/questions/68314103/issameorbefore-implementation-for-date-fns
                    value.isSameOrBefore( earliestDateObj ) || value.isSameOrAfter( deadline )
                  }
                  numberOfMonths={Breakpoints.appliesTo( 'md' ) ? 3 : 1}
                  placeholder="Or pick a date"
                  renderCalendarInfo={DatepickerNote}
                  showDefaultInputIcon
                  small
                  withPortal
                  onDateChange={( date ) => {
                    setDatePicker(( state ) => ({ ...state, date }));
                    onFilterChange({ arrives_before: date.format( 'YYYY-MM-DD' ) });
                  }}
                  onFocusChange={({ focused }) => {
                    setDatePicker(( state ) => ({ ...state, focused }));
                  }}
                />
              </div>
            </Checkbox>
          </li>
        </ul>
      )}
    </FilterItem>
  );
};

export default ArrivalFilter;
