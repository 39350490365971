import React from 'react';
import cls from 'classnames';

import { ISearchFilter } from 'src/explore/compositions/FilteredSearch/compositions/SearchFilter/types'; // FIXME: import
import { ISearchAggregations } from 'src/explore/types/shoppe';

import { TagFilter } from './elements/TagFilter';

interface TagFiltersProps {
  aggs: ISearchAggregations;
  availableFilters?: ISearchFilter[];
  className?: string;
  loading?: boolean;
}

// TODO: this is replicating what the FilterFactory does, we should consolidate the code
export const TagFilters = ({ aggs, availableFilters, className, loading }: TagFiltersProps ) => (
  <ul className={cls( 'ml-n1 spec__tag-filters', className )}>
    {availableFilters.map(( filter ) => (
      <TagFilter aggs={aggs} key={filter} loading={loading} name={filter} />
    ))}
  </ul>
);
