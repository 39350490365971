const format = ( value ) => {
  switch ( value ) {
    case null:
      return 'Recommended';
    case 'trending':
      return 'Popular Now';
    case 'best_sellers':
      return 'Best Sellers';
    case 'price_low':
      return 'Price: Lowest First';
    case 'price_high':
      return 'Price: Highest First';
    default:
      return '';
  }
};

export const LabelFormatter = {
  format,
};
