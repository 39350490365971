import React, {
  isValidElement,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as EppoSdk from '@eppo/js-client-sdk';
import cls from 'classnames';

import { Sorting } from 'src/explore/elements/Sorting';
import { TagFilters } from 'src/explore/elements/TagFilters';
import { useApiObject } from 'src/explore/hooks/useApiObject';
import ahoyTrack from 'src/explore/services/ahoy';
import ArrivalDateMapper from 'src/explore/services/productFilters/mappers/ArrivalDateMapper';
import URLParams from 'src/explore/services/URLParams';
import { IPromotion, ITrackingParams } from 'src/explore/types/shoppe';

import { useSelector } from 'react-redux';
import { IStore } from 'src/explore/types/store';
import { ISource, SearchFilter } from './compositions/SearchFilter';
import FiltersContext, {
  IAPIFilters,
  INITIAL_STATE as FILTERS_INITIAL_STATE,
} from './compositions/SearchFilter/elements/FiltersAndSorting/hooks/FiltersContext';
import { FiltersToParams } from './compositions/SearchFilter/services/FiltersToParams';
import { ISearchFilter } from './compositions/SearchFilter/types';
import { SortLabelFormatter } from './services/SortLabelFormatter';

import styles from './index.module.sass';

const buildURLParams = ( filters: IAPIFilters ) =>
  URLParams.buildURLParams( filters, {
    mappers: {
      arrives_before: ArrivalDateMapper.map,
    },
  });

const eppo = EppoSdk.getInstance();

const TAG_FILTERS: ISearchFilter[] = [
  'award_winner',
  'editor_pick',
  'fast_delivery',
  'free_shipping',
  'gb_top_gifts',
  'gorgeous_box',
  'has_gb_tv_video',
  'special_holiday',
  'on_sale',
];

export type { IAPIFilters };

export type ISearchParams = {
  source_id?: string;
  source_text?: string;
  source_type?: string;
  limit?: number;
  page?: number;
  cacheable?: number;
  video_offset?: number;
  product_offset?: number;
  max_num_videos?: number;
};

interface Props<T> {
  adjustQueryParams?: ( queryParams: ISearchParams ) => ISearchParams;
  apiPath: string;
  apiPathOverride?: string;
  apiResponseParser?: ( response: any ) => Promise<any>;
  availableFilters?: ISearchFilter[];
  availableSorting?: Array<string | null>;
  cacheable?: boolean;
  children?: ReactNode;
  conditions?: IAPIFilters;
  defaultSortOrder?: string;
  experimentName?: string;
  filterColumnClass?: string;
  filteredResultsColumnClass?: string;
  loadMore?: string | ReactElement;
  pageSize?: number;
  parseCollectionData?: ( collection: T[], page: number ) => Array<T | IPromotion>;
  source?: ISource;
  sidebar?: ReactNode;
  syncTrackingDataToPage?: ( data: ITrackingParams, page: T[]) => ITrackingParams;
  syncUrl?: boolean;
  title?: string;
  onChangePage?: ( page: number ) => void;
  onRenderContent: ( arg0: {
    data: T[];
    filterCount: number;
    loading: boolean;
    meta: any;
    onResetFilters: () => void;
  }) => ReactElement;
  onRenderHeader?: ({ content, filters }: { content: T[]; filters: IAPIFilters }) => void;
}

export const FilteredSearch = <T extends Object>({
  adjustQueryParams,
  apiPath,
  apiPathOverride,
  apiResponseParser,
  availableFilters = [],
  availableSorting = [],
  cacheable,
  children,
  conditions,
  defaultSortOrder,
  experimentName,
  filterColumnClass,
  filteredResultsColumnClass,
  loadMore,
  pageSize,
  parseCollectionData,
  source,
  sidebar,
  syncTrackingDataToPage,
  syncUrl,
  title,
  onChangePage,
  onRenderContent,
  onRenderHeader,
}: Props<T> ) => {
  // Services
  const urlParams = useRef( new URLParams());

  // Get filters from URL
  const initialFilters = useMemo(() => {
    if ( !availableFilters || !syncUrl ) return {};

    const parsedFilters = FiltersToParams.convert( availableFilters );

    return urlParams.current.mread( parsedFilters );
  }, [ availableFilters, syncUrl ]);

  const account = useSelector(( state: IStore ) => state.data.account );
  const [ content, setContent ] = useState<T[]>([]);
  const [ filters, setFilters ] = useState<IAPIFilters>( initialFilters );
  const [ loading, setLoading ] = useState<boolean>( true );
  const [ page, setPage ] = useState<number>( 1 );
  const [ sort, setSort ] = useState<string | null>(
    typeof defaultSortOrder === 'undefined' ? availableSorting[0] : defaultSortOrder
  );

  // This help us make sure users are not multi-clicking
  const currentPage = useRef( page );
  useEffect(() => {
    currentPage.current = page;
  }, [ page ]);

  // Set up the search query
  const query = useMemo(() => {
    const combinedFilters: { [key: string]: IAPIFilters[keyof IAPIFilters] } = { ...conditions };
    // We're only overriding the options passed in through conditions if is defined in filters.
    // filters represents the actual chosen filters by the user and many of them are undefined
    // if not selected.
    Object.keys( filters ).forEach(( key ) => {
      const typedKey = key as keyof IAPIFilters;
      if ( filters[typedKey]) {
        combinedFilters[typedKey] = filters[typedKey];
      }
    });

    if ( sort ) {
      combinedFilters.sort = sort;
    }

    return buildURLParams( combinedFilters );
  }, [ conditions, filters, sort ]);

  // Generate full query
  const completeQuery = useMemo(() => {
    if ( !apiPath ) return null;

    let params: ISearchParams = {};
    if ( source ) {
      if ( source.id ) {
        params.source_id = source.id;
      }
      if ( source.text ) {
        params.source_text = source.text;
      }
      if ( source.type ) {
        params.source_type = source.type;
      }
    }
    if ( pageSize ) {
      params.limit = pageSize;
      params.page = page;
    }
    if ( cacheable ) {
      params.cacheable = 1;
    }

    if ( adjustQueryParams ) {
      params = adjustQueryParams( params );
    }

    return `/${apiPath}?${query}&${URLParams.buildURLParams( params )}`;
  }, [ apiPath, cacheable, query, page, pageSize, source ]);

  // map available filters
  const availableTagFilters = useMemo(
    () =>
      availableFilters.filter(( availableFilter: ISearchFilter ) =>
        TAG_FILTERS.includes( availableFilter )
      ),
    [ availableFilters ]
  );
  const availablePanelFilters = useMemo(
    () =>
      availableFilters.filter(
        ( availableFilter: ISearchFilter ) => !TAG_FILTERS.includes( availableFilter )
      ),
    [ availableFilters ]
  );

  // Call API to retrieve content
  // FIXME: any data type
  const collection = useApiObject<any>( completeQuery, {
    responseParser: apiResponseParser,
    apiPathOverride,
  });

  const track = useCallback(
    ( data: any ) => {
      if ( data ) {
        if ( experimentName ) {
          const variation =
            window.eppo?.experimentOverrides[experimentName] ||
            ( window.ahoy.getVisitorToken()
              ? eppo.getStringAssignment(
                  experimentName,
                  String( account?.data?.meta_store?.account_id || window.ahoy.getVisitorToken()),
                  {},
                  null
                )
              : null );

          ahoyTrack( '$browserSearch', {
            ...data,
            'experiment-key': experimentName,
            'experiment-value': variation,
          });
        } else {
          ahoyTrack( '$browserSearch', data );
        }
      }
    },
    [ experimentName ]
  );

  const trackFb = useMemo(() => {
    let fired = false;

    return ( queryString: string, products: any ) => {
      if ( fired ) return;
      fired = true;
      if ( !!window.fbq && !!queryString ) {
        window.fbq( 'track', 'Search', {
          search_string: queryString,
          content_ids: products.slice( 0, 10 ).map(( p: any ) => p.id ),
          content_type: 'product',
        });
      }
    };
  }, []);

  // Store content locally
  useEffect(() => {
    if ( !collection.data ) return;

    let newContent = collection.data;
    trackFb( conditions?.query, newContent );

    if ( parseCollectionData ) {
      newContent = parseCollectionData( collection.data, currentPage.current );
    }

    if ( currentPage.current === 1 ) {
      // new search
      setContent( newContent );
    } else {
      // new page
      setContent(( currentContent ) => {
        newContent = currentContent.concat( newContent );
        return newContent;
      });
    }

    if ( collection.meta ) {
      track(
        syncTrackingDataToPage
          ? syncTrackingDataToPage( collection.meta.tracking_params, newContent )
          : collection.meta.tracking_params
      );
    }

    setLoading( false );
  }, [ collection.data ]);

  // Sync URL
  useEffect(() => {
    if ( !syncUrl ) return;

    urlParams.current.sync( filters );
  }, [ filters, syncUrl ]);

  // Reset state when query changes
  useEffect(() => {
    setPage( 1 );
    if ( onChangePage ) {
      onChangePage( 1 );
    }
    setLoading( true );
    setContent([]);
  }, [ query ]);

  // FIXME: this is ugly, but I don't care too much right now
  const filterCount = useMemo(() => {
    const queryString = buildURLParams( filters );

    return queryString ? queryString.split( '&' ).length : 0;
  }, [ filters ]);

  // Set the context for the components using filters
  const filtersContext = {
    ...FILTERS_INITIAL_STATE,
    appliedFilters: filters,
    onFilterChange: ( updatedFilters: IAPIFilters ) => {
      const result = { ...filters, ...updatedFilters };

      setLoading( true );
      setFilters( result );
    },
  };

  const aggs = collection.meta?.aggs;

  const hasMoreResults = useMemo(() => {
    if ( !pageSize ) return false;
    if ( collection.status !== 'success' ) return false;

    return collection.meta?.total_count > content.length;
  }, [ collection, pageSize, content ]);

  // If no results and no filters applied
  if ( filterCount === 0 && collection.meta?.total_count === 0 && children ) {
    return <>{children}</>;
  }

  const hasSideContent = !!availablePanelFilters.length || sidebar;

  return (
    <FiltersContext.Provider value={filtersContext}>
      {title && <h1>{title}</h1>}

      {!!onRenderHeader && onRenderHeader({ content, filters })}

      {( !!availableSorting.length || !!availableTagFilters.length ) && (
        <div
          className={cls(
            'filtered-search--container position-relative d-flex flex-column flex-lg-row mt-4 mt-lg-5',
            !availableTagFilters.length ? 'justify-content-end' : 'justify-content-between'
          )}
        >
          {!!availableTagFilters.length && (
            <TagFilters
              aggs={aggs}
              availableFilters={availableTagFilters}
              className={`${styles.tags} text-nowrap`}
              loading={loading}
            />
          )}

          {/* FIXME: sorting drop-down can overflow page with many tag filters on screens >= large */}
          {!!availableSorting.length && (
            <div
              className={cls(
                styles.sorting,
                'position-relative align-self-end align-self-lg-center',
                {
                  'mt-3 mt-lg-0': !!availableTagFilters.length,
                }
              )}
            >
              <Sorting
                className="rounded bg-white"
                labelFormatter={SortLabelFormatter.format}
                value={sort}
                values={availableSorting}
                onChange={setSort}
              />
            </div>
          )}
        </div>
      )}

      <div
        className={cls( 'row', {
          'mt-lg-6': !!availableSorting.length || !!availableTagFilters.length,
        })}
      >
        {hasSideContent && (
          <div
            className={cls(
              'col-lg-2 mt-lg-0 mb-4 mb-lg-0',
              {
                'mt-2': availableSorting.length === 0,
                [styles.sidebarWithSorting]: availableSorting.length > 0,
              },
              filterColumnClass
            )}
          >
            <div
              className={cls( 'pt-lg-3', {
                'mt-n1': availableSorting.length > 0,
              })}
            >
              {sidebar}

              <SearchFilter
                aggs={aggs}
                availablePanelFilters={availablePanelFilters}
                availableTagFilters={availableTagFilters}
                count={filterCount}
                loading={loading}
                sortLabelFormatter={SortLabelFormatter.format}
                sortOptions={availableSorting}
                sortSelection={sort}
                onSort={setSort}
              />
            </div>
          </div>
        )}

        <div
          className={cls( 'col', {
            'col-lg-10': hasSideContent,
            [filteredResultsColumnClass]: hasSideContent && filteredResultsColumnClass,
          })}
        >
          {onRenderContent({
            data: content,
            filterCount,
            loading,
            meta: collection.meta,
            onResetFilters: () => setFilters({}),
          })}

          <div className="d-flex justify-content-center pt-4 pb-9">
            {hasMoreResults && (
              <>
                {isValidElement( loadMore ) ? (
                  loadMore
                ) : (
                  <button
                    type="button"
                    className="btn btn-secondary spec__load-more"
                    onClick={() => {
                      setPage( page + 1 );
                      if ( onChangePage ) {
                        onChangePage( page + 1 );
                      }
                    }}
                  >
                    {loadMore || 'Show More'}
                  </button>
                )}
              </>
            )}

            {!loading && collection.loading && (
              <div className="spinner-border text-secondary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </FiltersContext.Provider>
  );
};
