import * as React from 'react';
import cls from 'classnames';

import { ISearchAggregationBucket } from 'src/explore/types/shoppe';

import FiltersContext, { IAPIFilters } from '../../../../hooks/FiltersContext';
import TreeFilter from '../TreeFilter';

interface Props {
  className?: string;
  cuisine: ISearchAggregationBucket[];
}

export const CuisineFilter: React.FC<Props> = ({ className, cuisine }) => {
  const {
    appliedFilters,
    onFilterChange,
  }: {
    appliedFilters: IAPIFilters;
    onFilterChange: ( filter: IAPIFilters ) => void;
  } = React.useContext( FiltersContext );

  if ( !cuisine?.length ) return null;

  return (
    <TreeFilter
      activeFilter={appliedFilters.cuisine}
      className={cls( 'spec__filter-cuisine', className )}
      defaultTitle="Cuisine"
      filters={appliedFilters.cuisine ? [] : cuisine}
      onBack={() => onFilterChange({ cuisine: undefined })}
      onNavigate={( filter: string ) => onFilterChange({ cuisine: filter })}
    />
  );
};
