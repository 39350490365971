import { useState } from 'react';

export default () => {
  const [ history, setHistory ] = useState([]);

  const push = ( value ) => {
    setHistory([ ...history, value ]);
  };

  const pop = () => {
    setHistory( history.slice( 0, -1 ));
  };

  return [ history, pop, push ];
};
