import React, { useMemo, useState } from 'react';
import { add } from 'date-fns';

import FiltersContext, {
  IAPIFilters,
  IContextState,
} from 'src/explore/compositions/FilteredSearch/compositions/SearchFilter/elements/FiltersAndSorting/hooks/FiltersContext'; // FIXME: import
import { ISearchFilter } from 'src/explore/compositions/FilteredSearch/compositions/SearchFilter/types'; // FIXME: import
import { ISearchAggregations, ISearchAggregationBucket } from 'src/explore/types/shoppe';

import { Button } from './elements/Button';

interface Props {
  aggs?: ISearchAggregations;
  disabled?: boolean;
  label?: string;
  loading?: boolean;
  name: ISearchFilter;
  isSelected?: boolean;
  onClick?: () => void;
}

export const Filter = ({
  aggs,
  disabled,
  label,
  loading,
  name,
  isSelected = false,
  onClick,
}: Props ) => {
  const [ isVisible, setIsVisible ] = useState<boolean>();

  const { appliedFilters, onFilterChange }: IContextState = React.useContext( FiltersContext );
  const text = useMemo(() => {
    if ( label ) return label;

    switch ( name ) {
      case 'award_winner':
        return 'Award Winner';
      case 'editor_pick':
        return 'Joe’s Pick';
      case 'free_shipping':
        return 'Free Shipping';
      case 'gb_top_gifts':
        return 'Top 100';
      case 'gorgeous_box':
        return 'Gorgeous Box';
      case 'has_gb_tv_video':
        return 'Shop by Video';
      case 'on_sale':
        return 'Sale';
      default:
        return name;
    }
  }, [ label, name ]);

  const isAvailable = useMemo(() => {
    if ( disabled ) return false;

    switch ( name ) {
      case 'award_winner':
        return !aggs
          ? true
          : aggs?.badges?.buckets.some(
              ( bucket: ISearchAggregationBucket ) =>
                bucket.key === 'award_winner' && bucket.doc_count > 0
            );
      case 'editor_pick':
        return !aggs
          ? true
          : aggs?.editor_pick?.buckets.some(
              ( bucket: ISearchAggregationBucket ) => bucket.key === 1 && bucket.doc_count > 0
            );
      case 'fast_delivery':
        return !aggs
          ? true
          : aggs?.earliest_delivery_dates?.buckets.some(
              ( bucket ) =>
                bucket.doc_count > 0 &&
                ( bucket.key as number ) <= add( new Date(), { days: 2 }).getTime()
            );
      case 'free_shipping':
        return !aggs
          ? true
          : aggs?.free_shipping?.buckets.some(
              ( bucket: ISearchAggregationBucket ) => bucket.key === 1 && bucket.doc_count > 0
            );
      case 'gb_top_gifts':
        return !aggs
          ? true
          : aggs?.badges?.buckets.some(
              ( bucket: ISearchAggregationBucket ) =>
                bucket.key === 'gb_top_gifts' && bucket.doc_count > 0
            );
      case 'gorgeous_box':
        return !aggs
          ? true
          : aggs?.badges?.buckets.some(
              ( bucket: ISearchAggregationBucket ) =>
                bucket.key === 'gorgeous_box' && bucket.doc_count > 0
            );
      case 'has_gb_tv_video':
        return !aggs
          ? true
          : aggs?.has_gb_tv_video?.buckets.some(
              ( bucket: ISearchAggregationBucket ) => bucket.key === 1 && bucket.doc_count > 0
            );
      case 'on_sale':
        return !aggs
          ? true
          : aggs?.on_sale?.buckets.some(
              ( bucket: ISearchAggregationBucket ) => bucket.key === 1 && bucket.doc_count > 0
            );
      default:
        return true;
    }
  }, [ aggs, disabled, name ]);

  if ( isVisible === undefined && !loading ) {
    setIsVisible( isAvailable );
  } else if ( isVisible === false && isAvailable ) {
    setIsVisible( true );
  }

  isSelected = isSelected || !!appliedFilters[name as keyof IAPIFilters]; // FIXME: types

  const handleClick = () => {
    if ( onClick ) {
      onClick();
    } else {
      onFilterChange({ [name]: isSelected ? undefined : true });
    }
  };

  if ( !isVisible ) return null;

  return (
    <li className="d-inline-block my-1 ml-1">
      <Button
        className={`spec__filter-${name}`}
        disabled={loading || !isAvailable}
        isSelected={isSelected}
        highlightRed={name === 'on_sale'}
        onClick={handleClick}
      >
        {text}
      </Button>
    </li>
  );
};
