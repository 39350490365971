import React, { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

import styles from './index.module.sass';

interface OptionProps {
  children: ReactNode;
  selected: boolean;
  onClick: () => void;
}

export const Option = ({ children, selected, onClick }: OptionProps ) => (
  <button
    className={`${styles.button} d-flex align-items-center p-0 border-0 bg-transparent text-dark font-weight-bold fs-5`}
    type="button"
    onClick={onClick}
  >
    {selected && (
      <FontAwesomeIcon className={`${styles.check} mr-2 fs-8 text-secondary`} icon={faCheck} />
    )}

    {children}
  </button>
);
