import * as React from 'react';
import cls from 'classnames';

import { ISearchAggregationBucket } from 'src/explore/types/shoppe';

import FiltersContext, { IAPIFilters } from '../../../../hooks/FiltersContext';
import TreeFilter from '../TreeFilter';

interface Props {
  className?: string;
  tags: ISearchAggregationBucket[];
}

export const DietMerchantFilter: React.FC<Props> = ({ className, tags }) => {
  const {
    appliedFilters,
    onFilterChange,
  }: {
    appliedFilters: IAPIFilters;
    onFilterChange: ( filter: IAPIFilters ) => void;
  } = React.useContext( FiltersContext );

  if ( !tags?.length ) return null;

  return (
    <TreeFilter
      activeFilter={appliedFilters.product_tags}
      className={cls( 'spec__filter-diet-merchant', className )}
      defaultTitle="Diet"
      filters={appliedFilters.product_tags ? [] : tags}
      onBack={() => onFilterChange({ product_tags: undefined })}
      onNavigate={( filter: string ) => onFilterChange({ product_tags: filter })}
    />
  );
};
