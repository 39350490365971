import React from 'react';
import { add, format } from 'date-fns';

import FiltersContext, {
  IContextState,
} from 'src/explore/compositions/FilteredSearch/compositions/SearchFilter/elements/FiltersAndSorting/hooks/FiltersContext'; // FIXME: import
import { ISearchAggregations } from 'src/explore/types/shoppe';

import { Filter } from '../Filter';

interface FastDeliveryFilterProps {
  aggs?: ISearchAggregations;
  loading?: boolean;
}

export const FastDeliveryFilter = ({ aggs, loading }: FastDeliveryFilterProps ) => {
  const { appliedFilters, onFilterChange }: IContextState = React.useContext( FiltersContext );

  const today = new Date();
  const dateString = format( add( today, { days: 2 }), 'yyyy-MM-dd' );

  const active = appliedFilters.fast_delivery === dateString;

  return (
    <Filter
      aggs={aggs}
      label="Fast Delivery"
      loading={loading}
      name="fast_delivery"
      onClick={() => onFilterChange({ fast_delivery: !active ? dateString : undefined })}
    />
  );
};
