import React from 'react';
import cls from 'classnames';

import styles from './index.module.sass';

interface LoaderProps {
  show: boolean;
}

export const Loader = ({ show }: LoaderProps ) => (
  <div
    className={cls(
      styles.loader,
      'd-flex justify-content-center justify-content-lg-start align-items-center align-items-lg-start bg-white',
      {
        [styles.show]: show,
      }
    )}
  >
    <div
      className={cls( styles.spinner, 'spinner-border', {
        [styles.show]: show,
      })}
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);
