import moment from 'moment';

const format = ( option ) => {
  switch ( option ) {
    case '':
      return 'no rush';
    case 'tomorrow':
      return 'tomorrow';
    case 'this_week':
      return 'this week';
    case 'next_week':
      return 'next week';
    default: {
      const d = moment( option );

      return d.isValid() ? d.format( 'l' ) : null;
    }
  }
};

export const OptionFormatter = {
  format,
};
