import { createContext } from 'react';

export type IAPIFilters = {
  arrives_before?: string;
  'badges[]'?: string[] | string;
  'categories.id'?: number;
  'categories.name'?: string;
  collection_ids?: string;
  city?: string;
  cuisine?: string;
  editor_pick?: boolean;
  has_gb_tv_video?: boolean;
  highlight?: string; // comma separated list of product ids
  holidays?: string;
  is_class?: boolean;
  is_sold_out?: boolean;
  fast_delivery?: string;
  free_shipping?: boolean;
  has_available_or_coming_soon_products?: boolean;
  max_price?: number;
  merchant_id?: number;
  min_price?: number;
  occasions?: string;
  on_sale?: boolean;
  past?: boolean;
  product_tags?: string;
  'product_types[]'?: string[] | string;
  query?: string;
  sort?: string;
  special_holiday?: string;
  'state.region_name'?: string;
  'state.name'?: string;
  tags?: string;
};

export type IContextState = {
  appliedFilters: IAPIFilters;
  onFilterChange: ( updatedFilters: IAPIFilters ) => void;
};

const INITIAL_STATE: IContextState = {
  appliedFilters: {},
  onFilterChange: () => null,
};

const FiltersContext = createContext( INITIAL_STATE );

export { FiltersContext as default, INITIAL_STATE };
