import React from 'react';

import { ISearchFilter } from 'src/explore/compositions/FilteredSearch/compositions/SearchFilter/types'; // FIXME: import

import { ISearchAggregations } from 'src/explore/types/shoppe';
import { Filter } from './elements/Filter';
import { HolidayFilter } from './elements/HolidayFilter';
import { BadgeFilter } from './elements/BadgeFilter';
import { FastDeliveryFilter } from './elements/FastDeliveryFilter';

interface TagFilterProps {
  aggs: ISearchAggregations;
  loading?: boolean;
  name: ISearchFilter;
}

export const TagFilter = ({ aggs, loading, name }: TagFilterProps ) => {
  switch ( name ) {
    case 'special_holiday':
      return <HolidayFilter aggs={aggs} loading={loading} />;
    case 'fast_delivery':
      return <FastDeliveryFilter aggs={aggs} loading={loading} />;
    case 'gorgeous_box':
      return <BadgeFilter aggs={aggs} loading={loading} name="gorgeous_box" />;
    case 'award_winner':
      return <BadgeFilter aggs={aggs} loading={loading} name="award_winner" />;
    case 'gb_top_gifts':
      return <BadgeFilter aggs={aggs} loading={loading} name="gb_top_gifts" />;
    default:
      return <Filter aggs={aggs} loading={loading} name={name} />;
  }
};
